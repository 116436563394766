.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
  width: 100%;
  text-align: center;
}

.image-list img {
  width: 225px;
  grid-row-end: span 2;
  border-radius: 55px;
}

.image-list div {
  margin-top: 10px;
}

.image-list img:hover {
  opacity: 0.8;
  cursor: pointer;
  border-radius: 0;
}
h2 {
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  text-align: center;
}

iframe {
  width: 100%;
  height: -webkit-fill-available;

}

.ui.segment {
  width: 100%;
}


.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-wrapper {
  max-height: 100vh;
}
.swiper-slide {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 20px;

}

.swiper-slide:hover {
  opacity: 0.8;
  cursor: pointer;
  border-radius: 0;
}

.container{
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.row {
  margin-right: 0px;
  margin-left: 0px; 
}

